import Footer from "containers/Footer";
import React from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "store";

const Layout = () => {
  const {user} = useAppSelector((state)=> state.app)
  return (
    <div className="max-w-[444px] w-full bg-[#ffd8db] min-h-[100vh] m-auto p-[16px]">
      <div className="pb-[30px]">
      <Outlet />  

      </div>
      {
        user &&  <Footer/>
      }
     
    </div>
  );
};

export default Layout;
