import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from "@mui/material";
import { message } from "antd";
import requestService from "api/request";
import CloseButton from "components/element/CloseButton";
import InputNumber from "components/element/InputNumber";

import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store";
import { setFetchDataWithdraw, setUser } from "store/app";
// import { useMutation, useQuery } from 'react-query';

type PopupProps = {
  onClose: any;
};

const PopupWithdraw = ({ onClose }: PopupProps) => {
  const { user } = useAppSelector((state) => state.app) as any;
  const [openBanking, setOpenBanking] = useState(false);
  const dispatch = useAppDispatch();
  const { control, handleSubmit } = useForm({ mode: "onChange" });
  const [loading, setLoading] = useState(false);

  const withdraw = async (value: any) => {
    if(!user?.bankInfo) return message.warning("Vui lòng liên kết ngân hàng để rút tiền !!!")
    setLoading(true);
    try {
      const res = await requestService.post("/profile/withdraw", {
        data: {
          moneyWithDraw: value,
        },
      });
      if (res && res?.data?.data) {
        dispatch(
          setUser({
            ...user,
            money: user?.money - value,
          })
        );
        message.success("Đã rút tiền thành công. Vui lòng chờ xác nhận");
        onClose()
        dispatch(setFetchDataWithdraw())
      }
    } catch (error : any) {
      console.log(error);
      message.error(error?.response?.data?.message)
    }
    setLoading(false);
  };

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      withdraw(values?.amount)
    })();
  };

  const isLink = false;

  return (
    <div
      className={
        "flex flex-col " + (isLink ? "" : "bg-primary-light min-h-screen")
      }
    >
      <CloseButton onClick={onClose} />
      <DialogTitle>Rút tiền</DialogTitle>
      {isLink ? (
        <DialogContent className="p-0">
          {/* <div className='flex justify-center h-[160px] py-2'>
            <img src={require('assets/images/User.BankMissing.png')} />
          </div> */}

          <Paper className="space-y-6 rounded-[24px] p-[16px] m-[12px] z-10 mt-[-80px] pt-[80px] pb-[40px]">
            <div className="mt-[40px]">
              <div className="text-error text-center mb-3">
                * Chưa liên kết ngân hàng
              </div>
              <div className="flex justify-center">
                <Button
                  variant="contained"
                  color="info"
                  className="w-[240px] rounded-full"
                  onClick={() => setOpenBanking(true)}
                >
                  Liên kết ngay
                </Button>

                {/* <Dialog open={openBanking} fullScreen>
                  <PopupBanking onClose={() => setOpenBanking(false)} />
                </Dialog> */}
              </div>
            </div>
          </Paper>
        </DialogContent>
      ) : (
        <DialogContent className="flex flex-col p-0">
          {/* <div className='flex justify-center h-[160px] py-0 z-10'>
            <img src={require('assets/images/User.AccountBalance.png')} />
          </div> */}

          <Paper className="flex-1 flex flex-col space-y-6 rounded-[24px] p-[16px] m-[12px] mt-[-80px] pt-[80px]">
            <Controller
              control={control}
              name="amount"
              defaultValue=""
              rules={{
                required: "Số tiền rút không được để trống",
                min: { value: 1, message: "Số tiền rút phải lớn hơn 0" },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="standard"
                  placeholder="Nhập số tiền rút"
                  InputProps={{
                    inputComponent: InputNumber,
                    inputProps: { maxLength: 12 },
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <div className="mt-[40px]">
              <div className="text-neutral bg-black/5 rounded-[24px]">
                <div className="font-bold border-b px-[24px] py-[20px]">
                  Thông tin ngân hàng
                </div>
                {user?.bankInfo ? (
                  <div className="px-[24px] py-[20px] space-y-1">
                    <div>{user?.bankInfo?.nameBank}</div>
                    <div>{user?.bankInfo?.numberBank}</div>
                    <div>{user?.bankInfo?.authorName}</div>
                  </div>
                ) : (
                  <div className="px-[24px] py-[20px] space-y-1">
                    Chưa liên kết thông tin
                  </div>
                )}
              </div>
            </div>

            {!isLink && (
              <DialogActions className="flex-1 items-end">
                <LoadingButton
                  variant="contained"
                  color="info"
                  className="w-[200px] rounded-full"
                  onClick={handleClickSubmit}
                  loading={loading}
                >
                  Gửi yêu cầu
                </LoadingButton>
              </DialogActions>
            )}
          </Paper>
        </DialogContent>
      )}
    </div>
  );
};

export default PopupWithdraw;
