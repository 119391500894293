import { Grid, IconButton } from "@mui/material";
import requestService from "api/request";
import Spinner from "components/element/Spinner";

import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { formatDate } from "utils/common";
const activeTab = "LEVEL_5";

export const convertResult = (item: string, index: number) => {
  const number = parseInt(item);
  if ((index === 0 || index === 1) && number < 5) return "LONG";
  if ((index === 0 || index === 1) && number >= 5) return "HỔ";
  if ((index === 2 || index === 3) && number < 5) return "DƯỚI";
  if ((index === 2 || index === 3) && number >= 5) return "TRÊN";
  if ((index === 4 || index === 5) && number % 2 === 0) return "CHẴN";
  if ((index === 4 || index === 5) && number % 2 === 1) return "LẺ";
};

const Award = () => {
  const navigate = useNavigate();
  const [result, setResult] = useState<any>([]);

  const getData = async () => {
    try {
      const res = await requestService.get("/profile/history-result");
      if (res && res.data) {
        setResult(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);


  return (
    <div>
      <div className="h-[70px] bg-white -container flex items-center p-4">
        <IconButton color="inherit" onClick={() => navigate(-1)}>
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBrZW/TsJQFMYP3aqbuPFnItEngAETGGRg8gHYlFE32XkHHXGE3YlEHCBKArwACJ2gTNJu0rH2O+UaWvo3+CUk3Pbc3z0999zvJkxLFCBVXdJ6vSRd13h8dpakVCpL6XQ2aBolvMCGsaV+/40Gg3eS5RPK5S4ZCIlFEFMqXVOhcMUxoeDFYkadzgvDMCmXu/DMSNc31O2+kqJ8Ua12y/EOmXsajT7MZrNhWnAzqhaLqTXn0RyPPx3P/8Dz+ZShmvZtxhXmAI5FHODt9mf3InqmpmfmDWZBEsphfQbXyK+e+8Lm4eeWmN/v93jM4MGgR9XqTSRoq/Xk+75QKDKLwQiW5VOrnc4pCrRef+A+9hKylmWZYyUcANGjx0D34aq6IgnNHhQcBwrhy9HjEkVSguJKQhmED3gJWdbr91bWz57d4BZikLUEM1GUWWBwHDjKkE5nSMIkGMp/wAE1DINjucalUsU6JEMKk4D7CaZULlf4P4MxQMZhWQu4V3fAFeF0+XzRfuA+68eZ0MxpQkLjsbDNaWSo7YqHtnlg9ChHu20bPfzD76jj0yeT4c7o7w4MzPdqwiRcT7h2ABd1xc5jcew+9gYbH+lqcsu2yRVp2obHySQWyYQe718qx4L6Ox5guAAAAABJRU5ErkJggg==" />
        </IconButton>
        <div className="text-sm text-primary-main font-semibold">KẾT QUẢ</div>
      </div>
      <div className="flex items-center my-3">
        <div className="text-secondary-dark font-medium text-xs">
          {formatDate(new Date().toISOString())}
        </div>
        <div className="text-center flex-1">
          <div className="text-sm font-semibold text-secondary-dark">
            CROW CASINO
          </div>
        </div>
      </div>
      <Spinner loading={false}>
        <Grid container className="mt-3">
          <Grid item xs={3}>
            <div className="text-center text-xs bg-[#FF91A4] py-2 px-4 text-white border border-[#FFD8DB]">
              Thời gian
            </div>
          </Grid>
          <Grid item xs={9}>
            <div className="text-center text-xs bg-[#FF91A4] py-2 px-4 text-white border border-[#FFD8DB]">
              Số tiền xổ số
            </div>
          </Grid>
          {!!result?.length &&  result.map((item : any, index: number) => (
            <Fragment key={index}>
              <Grid
                item
                xs={3}
                className="text-center bg-white border border-[#FFD8DB]"
              >
                <div className="px-3 py-4">
                  <div className="text-secondary-dark text-[10px]">
                    {formatDate(item?.timeEnd)}
                  </div>
                  <div className="text-secondary-dark text-[10px]">
                    Kì: {item?.id_custom}
                  </div>
                  {/* <div className='text-secondary-main text-[10px]'>17:20:00</div> */}
                </div>
              </Grid>
              <Grid item xs={9}>
                <Grid
                  container
                  className="h-full bg-white border border-[#FFD8DB] px-3 py-2"
                >
                  {(item?.results+'').split("").map((item, index) => (
                    <Grid
                      item
                      xs={2}
                      key={index}
                      columnSpacing={0}
                      className="flex flex-col items-center justify-center space-y-1"
                    >
                      <div
                        className="text-white w-[25px] h-[25px] rounded-full text-center"
                        style={{
                          background:
                            "linear-gradient(180deg, #FF6A84 0%, #DE3163 100%)",
                        }}
                      >
                        {item}
                      </div>
                      <div className="text-primary-main text-[10px] border border-[#FFD8DB] py-1 px-2">
                        {convertResult(item, index)}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Spinner>
    </div>
  );
};

export default Award;
