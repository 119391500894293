import { useCountdown } from "hooks/useCountDown";
import React, { memo, useEffect, } from "react";



const CountNumber = ({
  isCallback,
  setIsCallback,
  count,
}: {
  isCallback: any;
  setIsCallback: any;
  count: any;
}) => {
  const [minutes, seconds] = useCountdown(count);

  useEffect(() => {
    if (minutes <= 0 && seconds <= 0) {
      setIsCallback(!isCallback)
    }
  }, [minutes, seconds]);

  return (
    <div>
      <span style={{ color: "#111",fontSize:"14px",fontWeight:700 }}>
        {minutes || 0} : {seconds | 0}
      </span>
    </div>
  );
};

export default memo(CountNumber);