import { AccessTimeOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";

import { useEffect, useMemo, useState } from "react";
import { default as Countdown } from "react-countdown";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputNumber from "components/element/InputNumber";
import { message } from "antd";
import requestService from "api/request";
import { formatBalance } from "utils/common";
import { useAppDispatch, useAppSelector } from "store";
import PopupBet from "./Components/PopupPet";
import CountDownNumber from "./Components/CountDownNumber";
import { setUser } from "store/app";

const Casino = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.app) as any;
  console.log("🚀 ~ Casino ~ user:", user)
  const { control, watch, reset } = useForm({ mode: "onChange" });
  const [isCallBack, setIsCallBack] = useState(false);
  const [openBet, setOpenBet] = useState(false);
  const [gate, setGate] = useState("");
  const [result, setResult] = useState<any>(false);
  const dispatch = useAppDispatch()
  const { amount } = watch();
  const getResult = async () => {
    try {
      const res = await requestService.get("/profile/lottery-now");
      if (res && res.data) {
        setResult(res?.data?.data);
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
    }
  };
  const getUser = async () => {
    try {
      const res = await requestService.get("/profile/me");
      if (res && res.data) {
        dispatch(setUser(res?.data?.data));
      }
    } catch (error) {
      console.log(error);
      localStorage.clear();
      navigate("/login");
    }
  };

  useEffect(() => {
    getResult();
  }, [isCallBack]);

  useEffect(()=>{
    getUser()
  },[isCallBack])

  return (
    <div>
      <div className="h-[70px] bg-white -container flex items-center justify-between p-4">
        <div className="flex items-center">
          <IconButton color="inherit" onClick={() => navigate(-1)}>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBrZW/TsJQFMYP3aqbuPFnItEngAETGGRg8gHYlFE32XkHHXGE3YlEHCBKArwACJ2gTNJu0rH2O+UaWvo3+CUk3Pbc3z0999zvJkxLFCBVXdJ6vSRd13h8dpakVCpL6XQ2aBolvMCGsaV+/40Gg3eS5RPK5S4ZCIlFEFMqXVOhcMUxoeDFYkadzgvDMCmXu/DMSNc31O2+kqJ8Ua12y/EOmXsajT7MZrNhWnAzqhaLqTXn0RyPPx3P/8Dz+ZShmvZtxhXmAI5FHODt9mf3InqmpmfmDWZBEsphfQbXyK+e+8Lm4eeWmN/v93jM4MGgR9XqTSRoq/Xk+75QKDKLwQiW5VOrnc4pCrRef+A+9hKylmWZYyUcANGjx0D34aq6IgnNHhQcBwrhy9HjEkVSguJKQhmED3gJWdbr91bWz57d4BZikLUEM1GUWWBwHDjKkE5nSMIkGMp/wAE1DINjucalUsU6JEMKk4D7CaZULlf4P4MxQMZhWQu4V3fAFeF0+XzRfuA+68eZ0MxpQkLjsbDNaWSo7YqHtnlg9ChHu20bPfzD76jj0yeT4c7o7w4MzPdqwiRcT7h2ABd1xc5jcew+9gYbH+lqcsu2yRVp2obHySQWyYQe718qx4L6Ox5guAAAAABJRU5ErkJggg==" />
          </IconButton>
          <div className="text-sm text-primary-main font-semibold">
            CROW CASINO
          </div>
          <AccessTimeOutlined className="mx-1" />
          <div className="text-xs text-secondary-dark">
            {/* <Countdown
              overtime
              onComplete={()=>{setIsCallBack(!isCallBack)}}
              date={result?.result?.timeEnd ? new Date(result?.result?.timeEnd).getTime() - new Date().getTime() : 0}
              renderer={({ formatted: { minutes, seconds } }) => (
                <span className="font-medium">
                  {minutes}:{seconds}
                </span>
              )}
            /> */}
            <CountDownNumber
              count={new Date(result?.result?.timeEnd).getTime()}
              isCallback={isCallBack}
              setIsCallback={setIsCallBack}
            />
          </div>
        </div>
        <div className="text-xs text-secondary-dark font-medium">
          Số dư: {formatBalance(user?.money)}
        </div>
      </div>
      <Grid
        container
        columnSpacing={1 / 8}
        rowSpacing={1 / 8}
        className="text-center bg-[#FFBBC7] mt-2 p-1"
      >
        <Grid item xs={2}>
          <div className="bg-primary-main p-3 text-white text-xs font-semibold">
            {result?.resultBefore?.id_custom}
          </div>
        </Grid>
        <Grid item xs={10}>
          <Grid container className="h-full">
            {result?.resultBefore?.results &&
              (result?.resultBefore?.results + "")
                ?.split("")
                ?.map((item: any, index: number) => (
                  <Grid
                    item
                    xs={2}
                    className="flex items-center justify-center"
                    key={index}
                  >
                    <div
                      className="text-white w-[25px] h-[25px] rounded-full"
                      style={{
                        background:
                          "linear-gradient(180deg, #FF6A84 0%, #DE3163 100%)",
                      }}
                    >
                      {item}
                    </div>
                  </Grid>
                ))}
          </Grid>
        </Grid>
        {/* 2 BÊN TRÊN DƯỚI LONG HỔ */}
        <Grid item xs={2} className="bg-white flex items-center justify-center">
          <div className="uppercase text-secondary-dark font-medium text-xs p-2">
            2 bên trên dưới long hổ
          </div>
        </Grid>
        <Grid item xs={10}>
          <Grid container className="h-12">
            <Grid item xs={2} className="h-full border-l border-primary-light">
              <div
                className={
                  " text-white text-xs font-medium bg-primary-main h-full flex items-center justify-center cursor-pointer"
                }
              >
                <div>Hổ</div>
              </div>
            </Grid>
            <Grid item xs={2} className="h-full border-l border-primary-light">
              <div
                className={
                  " text-white text-xs font-medium bg-primary-main h-full flex items-center justify-center cursor-pointer"
                }
              >
                <div>Long</div>
              </div>
            </Grid>
            <Grid item xs={2} className="h-full border-l border-primary-light">
              <div
                className={
                  " text-white text-xs font-medium bg-primary-main h-full flex items-center justify-center cursor-pointer"
                }
              >
                <div>Dưới</div>
              </div>
            </Grid>
            <Grid item xs={2} className="h-full border-l border-primary-light">
              <div
                className={
                  " text-white text-xs font-medium bg-primary-main h-full flex items-center justify-center cursor-pointer"
                }
              >
                <div>Trên</div>
              </div>
            </Grid>
            <Grid item xs={2} className="h-full border-l border-primary-light">
              <div
                className={
                  " text-white text-xs font-medium bg-primary-main h-full flex items-center justify-center cursor-pointer"
                }
              >
                <div>Chẳn</div>
              </div>
            </Grid>
            <Grid item xs={2} className="h-full border-l border-primary-light">
              <div
                className={
                  " text-white text-xs font-medium bg-primary-main h-full flex items-center justify-center cursor-pointer"
                }
              >
                <div>Lẻ</div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            className="h-12 bg-white flex items-center justify-center border-y border-primary-light"
          >
            <div className="text-xs text-primary-main uppercase font-medium">
              số crow lớn nhỏ
            </div>
          </Grid>
          <Grid container className="h-12">
            <Grid
              item
              xs={6}
              className="h-full bg-white border-r border-primary-light"
            >
              <div
                className={
                  "h-full flex items-center justify-center space-x-1" +
                  (gate === "TREN" ? " bg-primary-light" : "")
                }
                // onClick={() => setGate("TREN")}
              >
                <span className="uppercase text-secondary-dark text-xs font-medium">
                  crow trên
                </span>
                <span className="text-xs text-primary-main font-medium">
                  1,1,9
                </span>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              className="h-full bg-white border-r border-primary-light"
            >
              <div
                className={
                  "h-full flex items-center justify-center space-x-1" +
                  (gate === "DUOI" ? " bg-primary-light" : "")
                }
                // onClick={() => setGate("DUOI")}
              >
                <span className="uppercase text-secondary-dark text-xs font-medium">
                  crow dưới
                </span>
                <span className="text-xs text-primary-main font-medium">
                  1,1,9
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            className="h-12 bg-white flex items-center justify-center border-y border-primary-light"
          >
            <div className="text-xs text-primary-main uppercase font-medium">
              số crow long hổ
            </div>
          </Grid>
          <Grid container className="h-12">
            <Grid
              item
              xs={6}
              className="h-full bg-white border-r border-primary-light"
            >
              <div
                className={
                  "h-full flex items-center justify-center space-x-1" +
                  (gate === "LONG" ? " bg-primary-light" : "")
                }
                // onClick={() => setGate("LONG")}
              >
                <span className="uppercase text-secondary-dark text-xs font-medium">
                  crow long
                </span>
                <span className="text-xs text-primary-main font-medium">
                  1,1,9
                </span>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              className="h-full bg-white border-r border-primary-light"
            >
              <div
                className={
                  "h-full flex items-center justify-center space-x-1" +
                  (gate === "HO" ? " bg-primary-light" : "")
                }
                // onClick={() => setGate("HO")}
              >
                <span className="uppercase text-secondary-dark text-xs font-medium">
                  crow hổ
                </span>
                <span className="text-xs text-primary-main font-medium">
                  1,1,9
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* VỊ TRÍ 2 SỐ CHẴN LẺ */}
        <Grid item xs={2} className="bg-white flex items-center justify-center">
          <div className="uppercase text-secondary-dark font-medium text-xs p-2">
            vị trí 2 số chẵn lẻ
          </div>
        </Grid>
        <Grid item xs={10}>
          <Grid
            container
            className="h-12 bg-white flex items-center justify-center border-y border-primary-light"
          >
            <div className="text-xs text-primary-main uppercase font-medium">
              vị trí quán quân
            </div>
          </Grid>
          <Grid container className="h-12">
            <Grid
              item
              xs={6}
              className="h-full bg-white border-r border-primary-light"
            >
              <div
                className={
                  "h-full flex items-center justify-center space-x-1" +
                  (gate === "CHAN" ? " bg-primary-light" : "")
                }
                onClick={() => setGate("CHAN")}
              >
                <span className="uppercase text-secondary-dark text-xs font-medium">
                  crow chẵn
                </span>
                <span className="text-xs text-primary-main font-medium">
                  1,1,9
                </span>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              className="h-full bg-white border-r border-primary-light"
            >
              <div
                className={
                  "h-full flex items-center justify-center space-x-1" +
                  (gate === "LE" ? " bg-primary-light" : "")
                }
                onClick={() => setGate("LE")}
              >
                <span className="uppercase text-secondary-dark text-xs font-medium">
                  crow lẻ <span className="text-primary-main">1,1,9</span>
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* BỘ SỐ */}
        <Grid item xs={2} className="bg-white flex items-center justify-center">
          <div className="uppercase text-secondary-dark font-medium text-xs p-2">
            bộ số
          </div>
        </Grid>
        <Grid item xs={10}>
          <Grid
            container
            className="h-12 bg-white flex items-center justify-center border-y border-primary-light"
          >
            <div className="text-xs text-primary-main uppercase font-medium">
              vị trí á quân
            </div>
          </Grid>
          <Grid container>
            {[
              "một",
              "hai",
              "ba",
              "bốn",
              "năm",
              "sáu",
              "bảy",
              "tám",
              "chín",
            ].map((item, key) => (
              <Grid
                item
                xs={4}
                key={item}
                className="h-12 bg-white border border-primary-light"
              >
                <div
                  className={
                    "h-full flex items-center justify-center space-x-1 cursor-pointer" +
                    (gate === "TRAM_NGAN_" + (key + 1)
                      ? " bg-primary-light text-white"
                      : "")
                  }
                  // onClick={() => setGate("TRAM_NGAN_" + (key + 1))}
                >
                  <span className="uppercase text-secondary-dark text-xs font-medium">
                    {item} <span className="text-primary-main">1,10</span>
                  </span>
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid
            onClick={() => setGate("TRAM_NGAN_0")}
            container
            className={
              "bg-white h-12 flex items-center justify-center border-y border-primary-light cursor-pointer"
            }
          >
            <div
              className={
                "text-xs text-primary-main uppercase font-medium w-full h-full items-center justify-center flex" +
                (gate === "TRAM_NGAN_0" ? " bg-primary-light text-white" : "")
              }
            >
              0 1,10
            </div>
          </Grid>
        </Grid>
      </Grid>

      <div className="flex items-end justify-between space-x-4 mt-5 mb-10">
        <Controller
          name="amount"
          defaultValue=""
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl>
              <label className="text-xs text-secondary-dark font-medium">
                Số Tiền Đặt:
              </label>
              <TextField
                {...field}
                size="small"
                sx={{
                  "& fieldset": { border: "none" },
                  input: {
                    color: "var(--color-primary-main)",
                    backgroundColor: "white",
                  },
                }}
                InputProps={{
                  inputComponent: InputNumber,
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <Button
          variant="contained"
          className="uppercase rounded-[20px] min-w-[160px]"
          onClick={() => {
            if (!gate) return message.warning("Vui lòng chọn chẳn hoặc lẻ");
            setOpenBet(true);
          }}
        >
          đặt cược
        </Button>
      </div>

      <Dialog open={openBet} onClose={() => setOpenBet(false)} fullWidth>
        <PopupBet
          onClose={() => setOpenBet(false)}
          amount={amount}
          gate={gate}
          incId={user?.phone || ""}
          result={result?.result?.id_custom}
          onSuccess={reset}
        />
      </Dialog>
    </div>
  );
};

export default Casino;
