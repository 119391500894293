import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  socket: null,
  isFetchWithDraw: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setFetchDataWithdraw: (state) => {
      state.isFetchWithDraw = !state.isFetchWithDraw;
    },
  },
});

export const { setUser,setFetchDataWithdraw } = appSlice.actions;

export default appSlice.reducer;
