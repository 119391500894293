import { Button, Dialog, IconButton, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TabDeposit from './TableDeposit';
import TabWithdraw from './TabWithdraw';
import useTabs from 'hooks/useTab';
import PopupNotification from 'components/element/PopupConfirm';
import PopupWithdraw from './PopupWithdraw';



const Fund = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const tabs = [
    { code: 'deposit', label: 'Lịch sử nạp tiền', component: <TabDeposit /> },
    { code: 'withdraw', label: 'Lịch sử rút tiền', component: <TabWithdraw /> },
  ];
  const [activeTab, onTabChange] = useTabs(tabs);

  return (
    <div>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHcSURBVHgBrZW/TsJQFMYP3aqbuPFnItEngAETGGRg8gHYlFE32XkHHXGE3YlEHCBKArwACJ2gTNJu0rH2O+UaWvo3+CUk3Pbc3z0999zvJkxLFCBVXdJ6vSRd13h8dpakVCpL6XQ2aBolvMCGsaV+/40Gg3eS5RPK5S4ZCIlFEFMqXVOhcMUxoeDFYkadzgvDMCmXu/DMSNc31O2+kqJ8Ua12y/EOmXsajT7MZrNhWnAzqhaLqTXn0RyPPx3P/8Dz+ZShmvZtxhXmAI5FHODt9mf3InqmpmfmDWZBEsphfQbXyK+e+8Lm4eeWmN/v93jM4MGgR9XqTSRoq/Xk+75QKDKLwQiW5VOrnc4pCrRef+A+9hKylmWZYyUcANGjx0D34aq6IgnNHhQcBwrhy9HjEkVSguJKQhmED3gJWdbr91bWz57d4BZikLUEM1GUWWBwHDjKkE5nSMIkGMp/wAE1DINjucalUsU6JEMKk4D7CaZULlf4P4MxQMZhWQu4V3fAFeF0+XzRfuA+68eZ0MxpQkLjsbDNaWSo7YqHtnlg9ChHu20bPfzD76jj0yeT4c7o7w4MzPdqwiRcT7h2ABd1xc5jcew+9gYbH+lqcsu2yRVp2obHySQWyYQe718qx4L6Ox5guAAAAABJRU5ErkJggg==" />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold uppercase'>CHI TIẾT QUỸ</div>
      </div>

      <div className='-container mt-3 flex align-middle flex-col justify-around'>
        <div className='flex justify-around'>
          <Button
            className='text-[10px] bg-white rounded-md border border-[#FFD8DB] w-[40%]'
            onClick={() => setOpen(true)}
          >
            Nạp tiền
          </Button>
          <Button
            className='text-[10px] bg-white rounded-md border border-[#FFD8DB] w-[40%]'
            onClick={() => setOpenWithdraw(true)}
          >
            Rút tiền
          </Button>
        </div>
        <div className='flex justify-around mt-4'>
          <Tabs value={activeTab} onChange={onTabChange} variant='fullWidth' className='w-full'>
            {tabs.map((tab) => (
              <Tab key={tab.code} label={tab.label} value={tab.code} />
            ))}
          </Tabs>
        </div>
        <div className='flex-1 bg-[#f3f3f3] p-[12px]'>
          {tabs.map((tab) => (
            <div key={tab.code} hidden={tab.code !== activeTab} className='mt-[12px]'>
              {tab.component}
            </div>
          ))}
        </div>
      </div>

      <Dialog open={open} fullWidth>
        <PopupNotification onClose={() => setOpen(false)} />
      </Dialog>
      <Dialog open={openWithdraw} fullScreen>
        <PopupWithdraw onClose={() => setOpenWithdraw(false)} />
      </Dialog>
    </div>
  );
};

export default Fund;


