import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material";

export const appTheme = createTheme({
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xs",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 25,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "text",
        disableElevation: true,
      },
      styleOverrides: {
        sizeLarge: { minHeight: 48, minWidth: 48 },
        sizeMedium: { minHeight: 40, minWidth: 40 },
        sizeSmall: { minHeight: 32, minWidth: 32 },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiDialog: {
      defaultProps: {
        maxWidth: "sm",
      },
    },
    MuiPagination: {
      defaultProps: {
        variant: "outlined",
        shape: "rounded",
        size: "medium",
        color: "primary",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "medium",
        color: "primary",
        InputLabelProps: { shrink: false },
      },
    },
  },
  typography: {
    fontFamily: "Inter",
    button: { fontWeight: 700, textTransform: "none" },
  },
  palette: {
    primary: {
      main: "#de3163",
      dark: "#FF6A84",
      light: "#FFD8DB",
    },
    secondary: {
      main: "#585858",
      dark: "#1e1e1e",
      light: "#959595",
    },
    mode: "light",
  },
});

const Theme = ({ children }: any) => {
  return (
    <ThemeProvider theme={responsiveFontSizes(appTheme)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
